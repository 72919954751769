var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeyType = void 0;
/** All supported key types */
var KeyType;
(function (KeyType) {
  KeyType[KeyType["ED25519"] = 0] = "ED25519";
})(KeyType = exports.KeyType || (exports.KeyType = {}));
export default exports;