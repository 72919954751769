import _key_pair_base from "./key_pair_base";
import _key_pair_ed from "./key_pair_ed25519";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeyPair = void 0;
const key_pair_base_1 = _key_pair_base;
const key_pair_ed25519_1 = _key_pair_ed;
class KeyPair extends key_pair_base_1.KeyPairBase {
  /**
   * @param curve Name of elliptical curve, case-insensitive
   * @returns Random KeyPair based on the curve
   */
  static fromRandom(curve) {
    switch (curve.toUpperCase()) {
      case "ED25519":
        return key_pair_ed25519_1.KeyPairEd25519.fromRandom();
      default:
        throw new Error(`Unknown curve ${curve}`);
    }
  }
  static fromString(encodedKey) {
    const parts = encodedKey.split(":");
    if (parts.length === 1) {
      return new key_pair_ed25519_1.KeyPairEd25519(parts[0]);
    } else if (parts.length === 2) {
      switch (parts[0].toUpperCase()) {
        case "ED25519":
          return new key_pair_ed25519_1.KeyPairEd25519(parts[1]);
        default:
          throw new Error(`Unknown curve: ${parts[0]}`);
      }
    } else {
      throw new Error("Invalid encoded key format, must be <curve>:<encoded key>");
    }
  }
}
exports.KeyPair = KeyPair;
export default exports;